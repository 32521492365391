<template>
  <div class="box-items">
    <div class="box-item">
      <img src="@/assets/image/cn/homepagebgd.jpg" alt="">
      <div class="box-body">
        <div class="item1">
          <img style="width: 209px;margin-right: 20px" src="@/assets/image/cn/beefuser.png">
          <div v-for="(item,index) in userInfo" :key="index">
            <div>{{ item. label }}</div>
            <div>
              <img :src=item.icon alt="">
              <countTo :startVal='item.startVal' :endVal='item.endVal' :duration='1800'></countTo>
              <span>+</span>
            </div>
          </div>
        </div>
        <div class="item2">
          <img src="@/assets/image/homepage/logo.png" alt="">
          <div class="beef-txt"><span>关于</span><span>必孚</span></div>
          <div>必孚（中国）</div>
          <div>进口牛肉行业第三方平台</div>
        </div>
        <div class="item3">
          <div>
            <p style="text-align: justify">必孚(中国)，亦称Beef to
              China(BTC)，成立于2014年，是进口牛肉行业信息及大数据服务提供商、现期货价格指数发布者及第三方现期货交易平台。</p>
            <p style="text-align: justify">
              中国牛肉消费市场需求庞大，2015-2023年牛肉的正关年度进口量从47万吨上升至274万吨，经中国海关总署准入的牛肉输华国从9个增加至30余个，获批的境外牛肉输华工厂从129家扩大至超600家。</p>
            <p style="text-align: justify">
              必孚成立至今，平台用户涵盖中国以及巴西、阿根廷、乌拉圭、美国、澳大利亚、新西兰在内的30多个中国海关总署准入的牛肉输华国家；牛肉输华准入工厂覆盖率达80%，中国牛肉进口商覆盖率达90%；政企性质用户数量超2万，包括
            </p>
          </div>
          <div>
            <p style="text-align: justify">
              中国海关等中国官方单位、国外政府部门或机构、国外驻华使领馆、国外行业协会、央企国企、外资企业、国内外金融机构以及不同规模的民营企业；行业群体涉及全产业链参与者，具体囊括国外农牧场、肉类屠宰加工厂、出口商、贸易商和国内进口商、代理商、加工厂、贸易商、批发商/档口、物流仓储、商超电商及餐饮终端。
            </p>
            <!--
            <div>
              <div class="infoclassbefore">●</div>
              <div class="infoclass">“必孚行情”通过对国内、外牛肉市场交易价格的掌握与监测，每日更新约15个国家、200个部位的现货实时行情变动、多产地活牛价格及期货价格波动，并逐步深拓行情厂号化、指数化以及草谷饲化。必孚行情已被产业链以进出口或大贸环节为主的企业用户以及参与融垫资环节的银行机构或代理商广泛用作现期货贸易参考及金融合约指数。</div>
            </div>
            <div>
              <div class="infoclassbefore">●</div>
              <div class="infoclass">“必孚交易”上线一年间，平台用户通过“线上集市”自主采销模式已实现超过20万吨牛肉交易量，“线下第三方交易服务”试运营仅百天，交易额突破1亿元。</div>
            </div>
            <div>
              <div class="infoclassbefore">●</div>
              <div class="infoclass">“必孚直播”为顺应线上直播与短视频商业形态的可视化发展，在疫情不间断突发造成线下商业交流障碍的大环境下，为广大行业用户提供了一个行业交流以及大贸(B端)采销的线上直播窗口。</div>
            </div>
             -->
            <p style="text-align: justify;margin-top:18px;">
              必孚始终秉承以第三方服务为宗旨，承蒙广大行业用户的信任和支持，平台自主开发的App及Web端为国内外上下游行业群体提供商业服务超10年，有幸成为行业平台头部品牌，我们将怀着感恩的心一直在路上，一如既往以用户服务、行业发展和社会福祉为己任。
            </p>
          </div>
        </div>
      </div>
    </div>
    <img style="width: 1200px;" class="img-body" src="@/assets/image/cn/homepage.png" alt="">
  </div>
</template>

<script>
import countTo from 'vue-count-to'

export default {
  name: 'HomePage',
  components: { countTo },
  data () {
    return {
      userInfo: [
        { label: '辐射国家数', icon: require('@/assets/image/homepage/world.png'), startVal: 0, endVal: 30 },
        { label: '国内用户数', icon: require('@/assets/image/homepage/china.png'), startVal: 0, endVal: 20000 },
        { label: '海外用户数', icon: require('@/assets/image/homepage/global.png'), startVal: 0, endVal: 5000 }
      ]
    }
  }
}
</script>

<style scoped>

@media screen and (width: 1366px) {
  .img-body {
    width: 1366px;
  }
  .box-item {
    width: 1366px;
    position: relative;
  }
}

@media screen and (min-width: 1920px) {
  .img-body {
    width: 1920px;
  }
  .box-item {
    width: 1920px;
    position: relative;
  }
}

@media screen and (min-width: 2048px) {
  .img-body {
    width: 2560px;
  }
  .box-item {
    width: 2560px;
    position: relative;
  }
}

.box-items{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.box-item {
  position: relative;
}
.box-item > img{
  width: 100%;
  height: 660px;
}

.box-item > div{
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.box-body{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #ffffff;
}

.item1{
  width: 780px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 10px 0;
  height: 120px;
}

.item1 > div{
  padding: 0 100px 0 20px;
  border-left: 2px solid #797979;
}

.item1 > div:last-child{
  border-right: 2px solid #797979;
}

.item1 > div > div:first-child{
  font-size: 14px;
  margin-bottom: 5px;
}
.item1  > div > div:last-child{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 30px;
  margin-bottom: 10px;
}

.item1  > div > div:last-child > img{
  width: 36px;
  height: 36px;
  margin-right: 50px;
}

.division{
  width: 100%;
  border-bottom: 2px solid #767676;
}

.item2{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  margin-top: 40px;
  color: #000000;
}
.item2 > img:first-child{
  width: 115px;
  height: 112px;
  margin-bottom: -120px;
  z-index: 3;
}

.item2 > div{
  font-size: 20px;
  margin-top: 20px;
}
.item2 > div:last-child{
  font-size: 16px;
}

.item3{
  font-size: 15px;
  width: 1100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  color: #525662;
}

.item3 > div{
  width: 525px;
  text-align: left;
}

.infoclassbefore
{
  display: inline-block;
}

.infoclass {
  display: inline-block;
  margin-left: 25px;
  margin-top: -21px;
  text-align: justify
}

.beef-txt{
  width: 500px;
  font-size: 100px !important;
  font-family: Source Han Sans CN !important;
  font-weight: 800;
  line-height: 100px;
  color: #D0D5DB;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

</style>
